.percent[data-v-70ca5a69] {
  text-align: end;
}
.percent[data-v-70ca5a69]::before {
  pointer-events: none;
  content: '';
  position: absolute;
  block-size: 100%;
  inline-size: var(--70ca5a69-size);
  inset-block: 0;
  inset-inline-start: 0;
  background-color: #6666FF66;
}
