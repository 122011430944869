.root[data-v-03bfa5ca] {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.root[data-v-03bfa5ca] > :first-child {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
