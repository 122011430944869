.pagination[data-v-40f01912] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ag[data-v-40f01912] .ag-group-value {
  flex: 1;
}
.ag[data-v-40f01912] .ag-full-width-container :deep(.ag-group-value) {
  flex: unset;
}
